@import url("https://fonts.googleapis.com/css2?family=Itim&display=swap"); /* "Itim", cursive */

:root {
  --primary-color: #9ddeff;
  --secondary-color: #f9fcf9;
  --dark-color: #001a23;
  --dark-color-transparent: #001a2344;
  --red-color: #ee7d70;

  --light-color: #ffffff;
  --light-grey: #f1f1f1;

  --border-radius: 1rem;
  --large-font: 1.4rem;
  --small-font: 1.1rem;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: "Itim", cursive;
  color: var(--dark-color);
  line-height: 1.5rem;
  font-weight: 300;
  background-color: var(--light-color);
  background-size: 4em;
}

h1 {
  margin: 0.6rem 0;
  line-height: 2.5rem;
}

ul {
  list-style-position: inside;
}

button {
  outline: none;
  border: none;
  text-align: center;
  font-size: var(--small-font);
  background-color: var(--light-color);
  font-family: "Itim", cursive;
  border: 1px solid var(--dark-color);
  border-radius: var(--border-radius);
}

button:hover {
  transform: scale(1.02);
  background-color: var(--secondary-color);
}

#dummyButton {
  color: var(--light-grey);
  border-color: var(--light-grey);
}

button#dummyButton {
  transform: none;
  background-color: var(--light-color);
}

.container {
  max-width: 700px;
  margin: 0 auto;
  padding: 0 2rem;
  position: relative;
}

.hidden {
  visibility: hidden;
}

/* Header */
.header {
  width: 104%;
  margin: 0 -2%;
  background-color: var(--primary-color);
  border-bottom: 1px solid var(--dark-color-transparent);
  overflow: hidden;
}

.mainHeading {
  font-size: 1.1rem;
  padding-top: 3rem;
  line-height: 1.1em;
  color: var(--light-color);

  text-shadow: -1px -1px 0 var(--dark-color-transparent),
    1px -1px 0 var(--dark-color-transparent),
    -1px 1px 0 var(--dark-color-transparent),
    1px 1px 0 var(--dark-color-transparent);
}

.mainHeadingBig {
  font-size: 3rem;
  margin-top: -0.5rem;
  color: var(--light-color);

  text-shadow: -1px -1px 0 var(--dark-color-transparent),
    1px -1px 0 var(--dark-color-transparent),
    -1px 1px 0 var(--dark-color-transparent),
    1px 1px 0 var(--dark-color-transparent);
}

.duck {
  opacity: 0.5;
  position: absolute;
  top: 0;
  right: 1rem;
}

/* App */
.app {
  background-color: var(--light-color);
  width: 104%;
  padding: 2rem 0;
  margin: 0 -2%;
  box-shadow: 0px 3px 3px -3px var(--dark-color-transparent);
}

.flexGame {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/* GameStarter */
.flexForm {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.spacer {
  height: 1.5rem;
}

.formLabel,
.formInput,
.errorField {
  font-size: var(--small-font);
  font-family: "Itim", cursive;
  justify-self: flex-start;
  align-self: flex-start;
  width: 100%;
}

.nameField,
.passwordField,
.urlField {
  outline: none;
  border: none;
  background-color: var(--light-color);
  border: 1px solid var(--dark-color);
  height: 2rem;
  border-radius: var(--border-radius);
  padding: 0 1rem;
}

.startButton,
.rollButton {
  min-height: 2rem;
  padding: 0 1rem;
}

.errorField {
  color: var(--red-color);
}

/* GameInfo */
.gameInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  flex-direction: row;
  flex-wrap: wrap;
}

.glass {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem;
  font-size: var(--small-font);
  padding: 0.5rem;
  padding-bottom: 0.2rem;
}

.highlightedGlass {
  background-color: var(--light-grey);
  border-radius: var(--border-radius);
}

.glassIcon {
  max-height: 6em;
}

.actionIndicator {
  font-size: var(--small-font);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/* Player info */
.playerInfo {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.playerList {
  border: 1px solid var(--dark-color);
  border-radius: var(--border-radius);
  padding: 0.2rem 1rem;
}

.playerInfoHeader {
  font-size: var(--small-font);
}

.imgEmoji {
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.3rem;
  display: block;
}

.tableNumber {
  text-align: right;
  min-width: 1.5rem;
  margin-right: 0.15rem;
  vertical-align: middle;
}

.tableText {
  text-align: left;
  min-width: 2rem;
  vertical-align: middle;
}

/* InfoHeader */
.veil {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fffffff2;
}

.flexTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.roundInfo {
  font-size: var(--small-font);
}

.resetButton {
  font-size: var(--small-font);
  width: 2rem;
  height: 2rem;
  text-align: center;
}

.resetButton img {
  width: 100%;
  height: 100%;
  padding: 0.33rem;
}

.infoDialog,
.rulesDialog {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

/* GlassSelector */
.glassSelector {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.glassSelectorList {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  flex-direction: row;
  flex-wrap: wrap;
}

.glassButton {
  width: 7rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

/* InviteMenu */
.inviteMenu {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}
